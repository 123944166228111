@use '../includes' as *;

$cpb: 650px;

.collection_thumbnail_container {
  container-type: inline-size;
  width: clamp(320px, 100%, 836px);
}

.collection_thumbnail {
  display: grid;
  grid-template-areas:
    'imageLink     title'
    'description   description';
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: $g8;

  @container (min-width: #{$cpb}) {
    grid-template-areas:
      'imageLink title      '
      'imageLink description'
      'imageLink button     ';
    gap: $g4 $g16;
  }
}

.collection_image_link {
  @include image-thumbnail-link;

  grid-area: imageLink;
}

.collection_fallback_link {
  @include image-thumbnail-link;

  grid-area: imageLink;
  aspect-ratio: 16 / 9;
  background-color: $blue-gray;
}

.collection_title {
  @include h4;

  grid-area: title;

  @container (min-width: #{$cpb}) {
    align-self: end;
  }
}

.collection_description {
  @include body-copy-3($pebble);

  grid-area: description;
  margin-block-end: $g4;
}

.view_collection_button {
  display: none;
  grid-area: button;
  align-self: start;
  justify-self: start;

  @container (min-width: #{$cpb}) {
    display: flex;
  }
}
